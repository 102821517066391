import React, { Component } from 'react'

import SEO from '../../components/SEO/SEO'
import Layout from '../../Layout/Layout'
import Hero from '../../components/Hero/Hero'
import Header from '../../components/Header/Header'
import Subheader from '../../components/Subheader/Subheader'
import Container from '../../components/Container/Container'

import ImageHero from '../../assets/images/sprzet/socage-da328.jpg'

class BlogPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Nowy podnośnik w naszej ofercie. 28m wysokości roboczej!"
          keywords={[
            'socage',
            'podnośnik',
            'zwyzka',
            'koszowa',
            'wynajem zwyzki 28m',
            'wynajem zwyzki rzeszow',
            'podnosnik koszowy stalowa wola',
          ]}
        />
        <Hero image={ImageHero}>
          <Header>
            Nowy podnośnik w naszej ofercie. 28m wysokości roboczej!
          </Header>
          <Subheader>
            Miło jest nam poinformować, że w ostatnim czasie nasza oferta
            została wzbogacona o nową zwyżkę.
          </Subheader>
        </Hero>
        <Container columns={1}>
          <Header as="h3">Socage DA-328</Header>
          <p>
            Ten podnośnik to Socage DA-328. Pozwoli on nam na wykonywanie prac
            wysokościowych do 28 metrów. Niewiele firm na terenie województwa
            podkarpackiego może pochwalić się takimi możliwościami. Od dzisiaj
            nasi klienci z Rzeszowa, Stalowej Woli, Tarnobrzega czy Mielca mogą
            korzystać z ogromnych możliwości jakie oferuje nam nasza nowa
            zwyżka.
          </p>
          <p>
            Wycinanie wysokich drzew, prace konserwacyjne na wysokich budynkach,
            odśnieżanie trudno dostępnych miejsc i daszków - to wszystko od
            dzisiaj jest już nam niestraszne dzięki Socage DA-328.
          </p>

          <Header as="h3">Najwyższa jakość prosto z Italii</Header>
          <p>
            Socage jest światowym liderem na rynku sprzętu i platform do pracy
            na wysokości. Historia firmy rozpoczęła się 30 lat temu w Modenie we
            Włoszech. Znalezienie niszy i specjalizacja w tego typu produkcji
            pozwoliła firmie osiągnąć niebywały sukces. Włoskie korzenie i
            narodowa pasja do mechaniki były receptą, która daje gwarancję
            najwyższej jakości.
          </p>
          <p>
            Jesteśmy dumni, że możemy oferować naszym klientom sprzęt o tak
            dobrych parametrach.
          </p>
        </Container>
      </Layout>
    )
  }
}

export default BlogPage
